body {
    background-color: #323232;
    color: #fff;
    width: 100vw;
    height: calc(100vh - 1.5em);
    margin: 0;
}

footer {
    text-align: center;
    a {
        color: #fff;
    }
}

#viewer {
    height: 100%;
}